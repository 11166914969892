.footer {

}
.footer__list {
  @include reset-list;

  padding: 25px 0 85px;
  border-top: 1px solid var(--blue-ACD8EA, #ACD8EA);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include adaptiveDesktopToMobile('gap', 55, 15);
}
.footer__item {
}
.footer__link {
  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
  font-size: 20px;
  line-height: normal;

  @include hover-focus {
    text-decoration: underline;
  }
}
