.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 28px;
  width: fit-content;

  border-radius: 8px;
  background: var(--Primary-B9FC6D, #B9FC6D);
  border: 2px solid var(--Primary-B9FC6D, #B9FC6D);

  color: var(--black-1F333C, #1F333C);
  font-size: 15px;
  font-weight: 450;
  line-height: normal;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  transition: $trans-default;

  @include hover-focus {
    background: transparent;
  }

  &--link {
    border-radius: 5px;
    background: var(--Blue-Ttitle, #335766);
    color: var(--Primary-B9FC6D, #B9FC6D);
    font-family: "TT Firs Neue";
    font-size: 17px;
    font-weight: 500;
    line-height: normal;
    text-transform: unset;
    padding: 3px 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    border: unset;

    &::after {
      content: url("../img/content/play.svg");
      margin-top: 1px;
    }

    @include hover-focus {
      background: var(--Primary-B9FC6D, #B9FC6D);
      color: var(--black-1F333C, #1F333C);
    }
  }
}
