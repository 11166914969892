.cases {
  @include adaptiveDesktopToMobile('padding-bottom', 200, 100);
}
.cases__title {
  text-align: center;
}
.cases__list {
  @include reset-list;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @include vp-1279 {
    grid-template-columns: auto;
  }
}
.cases___item {
  border-radius: 20px;
  background: var(--light-EEFAFF, #EEFAFF);
  @include adaptiveDesktopToMobile('padding', 40, 16);
}
.cases___item-header {
  display: flex;
  @include adaptiveDesktopToMobile('gap', 25, 10);

  @include vp-767 {
    flex-direction: column-reverse;
  }
}
.cases___item-photo {
}
.cases___item-main {
  display: flex;
  flex-direction: column;
}
.cases___item-logo {
}
.cases___item-period {
  margin-bottom: 16px;
}
.cases___item-period-title {
  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}
.cases___item-period-text {
  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
  font-size: 18px;
  line-height: 125%;
}
.cases___item-link {
  margin-top: auto;
}
.cases___item-results-title {
  @include adaptiveDesktopToMobile('margin-top', 34, 16);
  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}
.cases___item-results {
  padding-left: 20px;
  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
  font-size: 16px;
  line-height: 125%;
  max-width: 400px;
}
.cases___item-result {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
