.welcome {
  position: relative;
  @include adaptiveDesktopToMobile('padding-top', 255, 150);
  @include adaptiveDesktopToMobile('padding-bottom', 130, 95);
}
.welcome__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  z-index: -1;

  @include vp-767 {
    object-position: right;
  }
}
.welcome__title {
  color: var(--black-1F333C, #1F333C);
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 60, 30);
  font-weight: 700;
  line-height: 110%;
  text-transform: uppercase;
  margin: 0 auto 30px;
  max-width: 1000px;
}
.welcome__link {
  margin: 0 auto;
}
