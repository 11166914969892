.help {
  @include adaptiveDesktopToMobile('padding-bottom', 200, 100);
}
.help__title {
  text-align: center;
}
.help__list {
  @include reset-list;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @include vp-1023 {
    grid-template-columns: auto;
  }
}
.help__item {
  border-radius: 20px;
  background: var(--light-EEFAFF, #EEFAFF);
  @include adaptiveDesktopToMobile('padding', 32, 16);
  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));

  &--dark {
    background: var(--Blue-medium-416E81, #416E81);
    color: var(--light-EEFAFF, #EEFAFF);
  }
  &--green {
    background: linear-gradient(103deg, #B9FC6D 1.29%, #D3FFD8 83.99%);
  }

}
.help__item-number {
  color: var(--blue-ACD8EA, #ACD8EA);
  font-family: "TT Firs Neue";
  @include adaptiveDesktopToMobile('font-size', 18, 16);
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 10px;

  .help__item--green & {
    color: var(--Blue-medium-416E81, #416E81);
  }
}
.help__item-title {
  margin: 0 0 6px;
  font-family: "TT Firs Neue";
  @include adaptiveDesktopToMobile('font-size', 24, 20);
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}
.help__item-description {
  font-family: "TT Firs Neue";
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  margin: 0;
  max-width: 300px;
}
