.header {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  color: var(--black-1F333C, #1F333C);
  font-size: 16px;
  padding: 16px 0;
  @include adaptiveDesktopToMobile('padding-top', 65, 34);
  font-family: $ff-primary;

  .container {
    display: flex;
    align-items: center;

    @include vp-1023 {
      justify-content: space-between;
    }
  }
}
.header__logo {
  @include adaptiveDesktopToMobile('width', 122, 110);
}
.header__logo-img {
}
.header__menu {
  margin-left: auto;
  @include adaptiveFull('margin-right', 60, 0, 0);

  @include vp-1023 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: $trans-default;
    display: flex;
    justify-content: flex-end;

    &.is-active {
      opacity: 1;
      pointer-events: all;
      background: rgba(0, 0, 0, 0.41);
      backdrop-filter: blur(7.349999904632568px);

      .header__menu-list {
        @include vp-1023 {
          transform: translateX(0)
        }
      }
    }
  }
}
.header__menu-list {
  @include reset-list;

  display: flex;
  @include adaptiveFull('margin-left', -20, -15, -10);
  @include adaptiveFull('margin-right', -20, -15, -10);

  @include vp-1023 {
    background: var(--blue-ACD8EA, #ACD8EA);
    flex-direction: column;
    width: 100%;
    max-width: 520px;
    margin-left: auto;
    padding: 45px;
    height: 100%;
    transform: translateX(100%);
    transition: $trans-default;
  }
}
.header__menu-item {
  @include adaptiveFull('margin-left', 20, 15, 10);
  @include adaptiveFull('margin-right', 20, 15, 10);

  &--mobile {
    display: none;
  }

  @include vp-1023 {
    margin: 0;

    &--mobile {
      display: block;
    }
  }
}
.header__menu-logo {
  margin-bottom: 50px;
}
.header__menu-close {
  display: none;

  @include vp-1023 {
    z-index: 999;
    display: block;
    position: absolute;
    @include adaptiveFull('width', 36, 36, 35);
    @include adaptiveFull('height', 36, 36, 35);
    @include adaptiveFull('top', 35, 35, 35);
    @include adaptiveFull('right', 36, 36, 35);
  }
}
.header__menu-link {
  display: block;
  transition: $trans-default;
  @include adaptiveFull('font-size', 20, 16, 16);

  @include hover-focus {
    text-decoration: underline;
  }

  @include vp-1023 {
    padding-bottom: 20px;
  }
}
.header__menu-item-list {
  padding-top: 10px;
  @include reset-list;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: $trans-default;
  transform: translateY(100%);

  @include vp-1023 {
    position: static;
    transform: unset;
    display: none;
    padding: 0;
  }
}
.header__hamburger {
  display: none;

  @include vp-1023 {
    display: block;
  }
}
.header__hamburger-icon {
}
.header__social {
  display: flex;
  gap: 8px;
  margin-top: 65px;
}
