main {
  overflow: hidden;
}

body,
html {
  scroll-behavior: smooth;
}

section {
  scroll-margin-top: 50px;
}

.blurs {
  position: relative;
  overflow: hidden;
  background-image: url(../img/content/blurs.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 25dvh;

  &__bg {
    position: absolute;
    top: 85dvh;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    display: none;
  }
}

.title {
  &-h2 {
    color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
    @include adaptiveDesktopToMobile('font-size', 50, 30);
    font-weight: 700;
    line-height: 115%;
  }
}
