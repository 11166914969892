.when {
  position: relative;
  @include adaptiveDesktopToMobile('padding-top', 100, 50);
  padding-bottom: 50px;
}
.when__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  z-index: -1;

  @include vp-767 {
    top: 100%;
    transform: translateY(-50%);
  }
}
.when__title {
  @include adaptiveDesktopToMobile('width', 492, 340);
}
.when__description {
  @include adaptiveDesktopToMobile('margin-top', 30, 20);
  @include adaptiveDesktopToMobile('margin-bottom', 50, 30);

  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
  @include adaptiveDesktopToMobile('font-size', 18, 16);
  line-height: 125%;
  max-width: 574px;
}
.when__info {
  margin: 0;

  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
  @include adaptiveDesktopToMobile('font-size', 32, 20);
  font-weight: 450;
  line-height: 125%;
  max-width: 585px;
  margin-left: auto;
  @include adaptiveDesktopToMobile('margin-right', 145, 0);
}
