.about {
  @include adaptiveDesktopToMobile('padding-bottom', 200, 100);
}
.about__bg {

}
.about__title {

}
.about__list {
  @include reset-list;

  border-radius: 28px;
  background: var(--light-EEFAFF, #EEFAFF);

  @include adaptiveDesktopToMobile('padding', 75, 25);
}
.about__item {
  display: grid;
  grid-template-columns: 1.56fr 1fr 1.19fr;
  gap: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--blue-ACD8EA, #ACD8EA);
    @include adaptiveDesktopToMobile('padding-bottom', 50, 25);
    @include adaptiveDesktopToMobile('margin-bottom', 100, 50);
  }

  @include vp-1279 {
    grid-template-columns: 1.75fr 1fr;
  }

  @include vp-767 {
    grid-template-columns: auto;
  }
}
.about__item-main {
  @include adaptiveDesktopToMobile('padding-right', 50, 0);
}
.about__item-title {
  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
  @include adaptiveDesktopToMobile('font-size', 36, 22);
  font-weight: 700;
  line-height: normal;
  margin: 0 0 14px;
}
.about__item-link {
  margin-bottom: 20px;
}
.about__item-subtitle {
  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
  @include adaptiveDesktopToMobile('font-size', 22, 18);
  font-weight: 700;
  line-height: normal;
  margin: 0 0 13px;
}
.about__item-description {
  color: var(--Blue-medium-416E81, #416E81);
  @include adaptiveDesktopToMobile('font-size', 17, 16);
  font-weight: 400;
  line-height: 140%;
  margin: 0;
}
.about__item-photo {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  overflow: hidden;
}
.about__info {
  color: var(--Blue-medium-416E81, #416E81);
  @include adaptiveDesktopToMobile('font-size', 17, 16);
  font-weight: 400;
  line-height: 140%;
  margin: 0;

  @include vp-1279 {
    grid-column: span 2;
  }

  @include vp-767 {
    grid-column: auto;
  }
}
.about__item-focus {
  grid-column: span 3;

  @include vp-1279 {
    grid-column: span 2;
  }

  @include vp-767 {
    grid-column: auto;
  }
}
.about__item-focus-title {
  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 20px;
}
.about__item-focus-list {
  @include reset-list;

  display: flex;
  flex-wrap: wrap;
  gap: 14px;

  @include vp-767 {
    gap: 10px;
  }
}
.about__item-focus-list-item {
  border-radius: 22px;
  background: var(--Primary-B9FC6D, #B9FC6D);
  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
  font-size: 15px;
  font-weight: 400;
  line-height: 140%;
  padding: 8px 20px;

  @include vp-767 {
    font-size: 13px;
    padding: 6px 15px;
  }
}
