.main-nav {
  &__list {
    display: flex;
    margin: 0;
    margin-right: 20px;
    margin-left: 60px;
    padding: 0;

    list-style: none;
  }

  &__item {
    margin-right: 20px;
  }

  &__link {
    color: $color-default-white;

    &.is-active {
      opacity: 0.6;
    }
  }

  &__toggle {
    display: none;
  }
}
