.friends {
  @include adaptiveDesktopToMobile('padding-bottom', 200, 100);
}
.friends__title {
  text-align: center;
}
.friends__list {
  @include reset-list;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @include vp-1023 {
    grid-template-columns: auto;
  }
}
.friends__item {
}
.friends__item-logo-wrapper {
  border-radius: 20px;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 4px;
}
.friends__item-logo {
}
.friends__item-main {
  border-radius: 20px;
  background: #FFF;
  padding: 20px;
  display: flex;
  gap: 20px;
}
.friends__item-person {
  flex-shrink: 0;
}
.friends__item-person-img {
  margin-bottom: 10px;
}
.friends__item-person-name {
  text-align: center;
  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
}
.friends__item-description {
  margin: 0;
  color: var(--Blue-medium-416E81, #416E81);
  font-size: 16px;
  line-height: 125%;
}
