.contacts {
  @include adaptiveDesktopToMobile('padding-bottom', 200, 100);
}
.contacts__title {
  text-align: center;
  margin: 0 0 10px;
}
.contacts__description {
  margin: 0 auto;
  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
  text-align: center;
  font-size: 24px;
  font-weight: 450;
  line-height: 148%;
  max-width: 350px;
  @include adaptiveDesktopToMobile('padding-bottom', 40, 20);
}
.contacts__link {
  margin: 0 auto;
  width: 300px;
  max-width: 100%;
}
.contacts__list {
  @include reset-list;
  @include adaptiveDesktopToMobile('margin-top', 40, 20);

  display: flex;
  @include adaptiveDesktopToMobile('gap', 65, 15);
  justify-content: center;
  flex-wrap: wrap;
  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
  font-size: 29px;
  font-weight: 500;
  line-height: normal;
}
.contacts__item {
  transition: $trans-default;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;

  &::after {
    content: url('../img/content/play-dark.svg');
    margin-bottom: 2px;
  }

  @include hover-focus {
    text-decoration: underline;
  }
}
.contacts__item-link {
  color: var(--dark-blue-335766, var(--Blue-Ttitle, #335766));
}
